import { Space, Divider, Title } from '@mantine/core';

import {NavMenu} from "../components/GetNavMenu";
import {Footer} from "../components/GetFooter";
import {HeroHeader} from "../components/headers/GetHeroHeader";
import {SystemMemoryProps} from "../models/SystemMemoryProps";
import {RenderPortfolioError} from "../components/portfolio/PortfolioError";
import {useStyles} from "../models/styles/NotFoundStyle";

export function Home({memory}: SystemMemoryProps) {
    const { classes } = useStyles();

    return (
        <>
        <NavMenu></NavMenu>
            <HeroHeader></HeroHeader>
            <Divider size={5}></Divider><br/>
            <center>
                <Space h="md" />
                <Title order={1} className={classes.title}>Recent Projects</Title>

                {RenderPortfolioError()}
            </center>
        <Footer data={[]}></Footer>
        </>
    )
}