import { Image } from '@mantine/core';

interface TomSWLogoProps {
    width: number;
    height: number;
}

export function TomSWLogo({width, height}: TomSWLogoProps) {
    return (
        <Image
            width={width}
            height={height}
            fit="contain"
            src="/assets/images/tomsw-logo.png"
        />
    );
}