import React, { Suspense, lazy, ComponentType } from 'react';
import { MantineProvider } from '@mantine/core';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { NotFound } from './pages/errors/404';
import { MemoryModel } from "./models/MemoryModel";
import {Login} from "./pages/auth/Login";

export default function App() {
    const model: MemoryModel = {
        ApiURL: "https://localhost:7239/",
        CFSiteKey: "0x4AAAAAAADsUWPv4ig1sdYO",
        AccessToken: "",
        isSignedIn: false,
        Cache: []
    }
    const Memory = React.useState(model);

    return (
        <MantineProvider theme={{ colorScheme: 'dark' }} withGlobalStyles withNormalizeCSS>
            <BrowserRouter>
                <Routes>
                    <Route index element={<Home memory={Memory} />} />
                    // Authentication routes
                    <Route path="login" element={<Login memory={Memory}/>} />
                    <Route path="*" element={<NotFound memory={Memory} />} />
                </Routes>
            </BrowserRouter>
        </MantineProvider>
    );
}