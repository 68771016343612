import {Card, Container, Text, Title} from "@mantine/core";
import { IconAlertTriangle } from '@tabler/icons-react';
import {useStyles} from "../../models/styles/NotFoundStyle";

export function RenderPortfolioError() {
    const { classes } = useStyles();

    return (
        <><br/><Container>
            <Card shadow="sm" padding="lg" radius="md" withBorder >
            <IconAlertTriangle size={192} stroke={2} />
            <Title className={classes.title}>No projects found!</Title><Text color="dimmed" size="lg"
                                                                                   align="center"
                                                                                   className={classes.description}>
            Unfortunately, there are no projects to display. Please check back another time.
            </Text>
            </Card>
        </Container></>
    );
}