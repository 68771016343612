import { createStyles, Title, Text, Button, Container, Group } from '@mantine/core';
import { Link } from 'react-router-dom';
import {NavMenu} from "../../components/GetNavMenu";
import {Footer} from "../../components/GetFooter";
import {useStyles} from "../../models/styles/NotFoundStyle";
import {SystemMemoryProps} from "../../models/SystemMemoryProps";

export function NotFound({memory}: SystemMemoryProps) {
    const { classes } = useStyles();

    return (
        <><NavMenu></NavMenu><Container className={classes.root}>
            <div className={classes.label}>404</div>
            <Title className={classes.title}>This is a hidden page!</Title>
            <Text color="dimmed" size="lg" align="center" className={classes.description}>
                Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has
                been moved to another URL.
            </Text>
            <Group position="center">
                <Link style={{textDecoration: 'none'}} to="/"><Button variant="outline" color="indigo" size="md">
                    Return to home page
                </Button></Link>
            </Group>
        </Container><Footer data={[]}></Footer>
        </>
    );
}